import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import CIcon from "@coreui/icons-react";
import {cilLink} from "@coreui/icons-pro";
import {EnvelopeAt, Facebook, Instagram, Link45deg} from "react-bootstrap-icons";

const icons = {
  email: EnvelopeAt,
  facebook: Facebook,
  instagram: Instagram,
}

const SocialMedia = ({socialMedia = []}) => {
  const [items, setItems] = useState([])
  useEffect(() => {
    const items = setItems(socialMedia.filter((sm) => sm.type !== "email"))
  }, [socialMedia]);
  return <>
    {items.map((item) => {
      const BIcon = icons[item.type] ?? Link45deg
      return <React.Fragment key={item.url}>
        <Link to={item.url} target={"_blank"} title={item.type}>
          <BIcon size={"1.6rem"} />
          <CIcon icon={icons[item.type] ?? cilLink} size={"xl"} />
        </Link>
      </React.Fragment>
    })}
  </>
}

export default SocialMedia

import React from 'react'
import { CPopover } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons-pro'
import { opTexts } from '../../../tools/set-seg-tag'

const texts = {
  or: 'Your search will include locations that mention one or more of the selected terms',
  and: 'A location is required to mention all of the selected terms in this column to appear in your search',
  not: 'Clinics mentioning these terms will be excluded from the search',
}


const SidebarHelp = ({ ops = ['or'] }) => {

  return (
    <div
      style={{
        margin: "0 var(--cui-offcanvas-padding-x)",
        padding: "0 var(--cui-offcanvas-padding-x)"
      }}
    >
    <div
      style={{
        textAlign: 'right',
        marginLeft: "auto",
        marginRight: "10%",
        width: "16rem"
      }}
    >
      <div style={{ display: 'flex', justifyContent: "space-around" }}>
        <>{ops.map((op) => <div style={{ textAlign: "center", width: "100%"}} key={`sidebar-hop-${op}`}>
          <CPopover content={texts[op]} trigger={"hover"}>
            <span style={{cursor: "pointer"}}>

            {opTexts[op]}{' '}
              <CIcon icon={cilInfo} size={"sm"} />
            </span>

          </CPopover>
        </div>)}</>
      </div>
    </div>
    </div>
  )
}

export default SidebarHelp

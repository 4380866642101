import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { LIST_ONLY_USERS } from '../../graphql/queries/user-manage'
import { CFormSelect } from '@coreui/react'

const UserSelect = ({ onChange, selected }) => {
  const [entries, setEntries] = useState([])
  const [options, setOptions] = useState([])

  const { loading } = useQuery(LIST_ONLY_USERS, {
    onCompleted({ ListSubUsers: { entries: en } }) {
      setEntries(
        en.map((user) => ({
          value: user.id,
          label: user.name,
        })),
      )
    },
  })

  useEffect(() => {
    setOptions(['All', ...entries])
  }, [entries])

  return (
    <>
      <CFormSelect
        value={selected}
        options={[
          // {label:"Filter By Creator", value: "-1"},
          { label: 'All (click to filter by creator name)', value: '' },
          ...entries,
        ]}
        onChange={(e) => onChange(e.target.value)}
      />
    </>
  )
}

export default UserSelect

import React from "react";
import CIcon from "@coreui/icons-react";
import {cisHamburgerMenu} from "@coreui/icons-pro";
import {useReactiveVar} from "@apollo/client";
import {showSidebar} from "../../../../graphql/policies/nav";

const NavClose = () => {

  const isSidebarVisible = useReactiveVar(showSidebar);

  return <span
    className={"monitor-sidebar__toggle"}
    onClick={() => showSidebar(!isSidebarVisible)}
  >
    <CIcon icon={cisHamburgerMenu} size={"xl"} />
  </span>
}

export default NavClose

import {gql} from "@apollo/client";

const USER_FRAGMENT = gql`
  fragment UserDetails on AppUser {
    id email name permissions {
      allow deny
    }
  }
`

export const LIST_ONLY_USERS = gql`
  query ListOnlyUsers {
    ListSubUsers {
      entries {
        name id
      }
    }
  }
`
export const LIST_SUB_USERS = gql`
  ${USER_FRAGMENT}
  query ListSubUsers {
    subscription {
      seats
    }
    ListSubUsers {
      entries {
        ...UserDetails
        search
      }
      totalCount
    }
  }
`

export const DELETE_SUB_USER = gql`
  mutation DeleteSubUser($id: String!) {
    DeleteSubUser(id: $id )
  }
`

export const READ_SUB_USER = gql`
  ${USER_FRAGMENT}
  query ReadSubUser($id: String!) {
    ReadSubUser(id: $id) {
      ...UserDetails
    }
  }
`

export const CREATE_SUB_USER = gql`
  ${USER_FRAGMENT}
  mutation CreateSubUser($input: CreateUserInput!) {
    CreateSubUser(input: $input) {
      ...UserDetails
    }
  }
`

export const UPDATE_SUB_USER = gql`
  ${USER_FRAGMENT}
  mutation UpdateSubUser($input: UpdateUserInput!) {
    UpdateSubUser(input: $input) {
      ...UserDetails
    }
  }
`

export const SET_USER_ROLE = gql`
  ${USER_FRAGMENT}
  mutation SetUserRole($id: String! $allow: [String!] $deny: [String!]) {
    SetUserRole(id: $id roles: {deny: $deny allow: $allow} ) {
      ...UserDetails
    }
  }
`

import React, { useEffect, useState } from 'react'
import Form, { useForm } from 'rc-field-form'
import { FormField, Input, TextArea } from '../../../Form'
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CListGroup,
  CListGroupItem,
  CRow,
  CSpinner,
} from '@coreui/react'
import TerritoryMap from './TerritoryMap'

const TerritoryForm = ({
  territory,
  onCancel = async () => void 0,
  onComplete = async (values) => void 0,
  loading = false,
  disableOnComplete = false,
}) => {
  const [form] = useForm()
  const [fields, setFields] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [selectedTab, setSelectedTab] = useState('map')
  useEffect(() => {
    setTimeout(() => {
      form.resetFields()
      if (territory) {
        const { codes = [] } = territory
        setSelected(codes)
      } else {
        setSelected([])
      }
    }, 100)
  }, [territory])

  const [selected, setSelected] = useState([])

  const updateCodes = (codes = []) => {
    if (!disabled) {
      form.setFieldValue('codes', codes)
      form.setFields([{ name: 'codes', touched: true }])
      setSelected(codes)
    }
  }

  const removeCode = (code = '') => {
    let fixed = [...selected]
    const idx = fixed.findIndex((c) => c === code)
    if (idx !== -1) {
      fixed.splice(idx, 1)
      setSelected(fixed)
    }
  }

  const resetForm = () => {
    if (territory) {
      const { codes = [] } = territory
      updateCodes(codes)
    }
    form.resetFields()
  }

  return (
    <>
      <Form
        initialValues={territory}
        form={form}
        onFieldsChange={(changed, all) => {
          setFields(all)
        }}
        onFinish={(values) => {
          if (disableOnComplete) {
            setDisabled(true)
          }
          onComplete(values)
        }}
      >
        <FormField
          name={'name'}
          label={'Label'}
          rules={[{ required: true, message: 'Name is required' }]}
        >
          <Input disabled={disabled} />
        </FormField>
        <FormField label={'Description (optional)'} name={'description'}>
          <TextArea disabled={disabled} />
        </FormField>
        <FormField
          name={'codes'}
          label={'Postcode Locations'}
          validateTrigger={'onSubmit'}
          rules={[{ required: true, message: 'You must select at least one postcode area.' }]}
          horizontal
        >
          <Input type={'hidden'} disabled />
        </FormField>

        <hr />
        <CRow>
          <CCol md={6} sm={12}>
            <TerritoryMap
              onChange={updateCodes}
              selected={selected}
              findBounds={territory ? !!territory.id : false}
            />
          </CCol>
          <CCol md={6} sm={12}>
            <CAccordion style={{ maxWidth: '80rem' }} className={'mb-2'}>
              <CAccordionItem>
                <CAccordionHeader>Show Territory List</CAccordionHeader>
                <CAccordionBody>
                  <CListGroup>
                    <>
                      {selected.map((t) => (
                        <CListGroupItem
                          key={`territory-${t}`}
                          className={'d-flex justify-content-between align-items-center'}
                        >
                          {t}
                          <CButton color={"primary"} size={'sm'} onClick={() => removeCode(t)}>
                            Remove
                          </CButton>
                        </CListGroupItem>
                      ))}
                    </>
                  </CListGroup>
                </CAccordionBody>
              </CAccordionItem>
            </CAccordion>
            <CCard>
              <CCardBody>
                <CButton color={"primary"} onClick={() => setSelected([])}>Clear All</CButton>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>

        <hr />
        <CRow className={'mb-3'}>
          <CCol>
            <CButton color={"primary"} type={'submit'} disabled={loading || disabled}>
              {loading && <CSpinner size={'sm'} />}Save
            </CButton>{' '}
            <CButton color={"primary"} onClick={() => resetForm()} disabled={disabled}>
              Reset
            </CButton>
          </CCol>
        </CRow>
      </Form>
    </>
  )
}

export default TerritoryForm

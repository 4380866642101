import {createContext, useCallback, useEffect, useReducer, useState} from "react";
import {useReactiveVar} from "@apollo/client";
import {setTerritoryJSONCB} from "../graphql/policies/search";
import axios from 'axios'
import {getAuthToken} from "../auth";
import {featureCollection} from "@turf/helpers";
import {center as turfCenter} from "@turf/center"
import {bbox} from "@turf/bbox"
import dissolve from "@turf/dissolve";

export const GeoJSONDataContext = createContext({
  loading: false,
  geoJSON: {
    empty: {
      features: [{type: "Feature"}]
    }
  },
  groups: [],
  loadGeoJSON: async () => void(0),
  getGroupGeoData: (visType, ids) => void (0),
  displayGroups: false,
  selectedAreaIds: [],
  liveGroupId: "",
  groupEditMode: false,
  mapMode: "data",
  // readGeoJSON: () => void(0)
})

export const GeoJSONDispatchContext = createContext((type, value) => void(0))

const groupReducer = (state = defaultState, {type, value}) => {
  let finalState = {...state}

  switch(type) {
    case "SET_GEOJSON": {
      const {geoJSON} = {...state}
      finalState = {
        ...state,
        geoJSON: {
          ...geoJSON,
          ...value,
        }
      }
      break;
    }
    case "ADD_GROUP": {
      let {groups} = {...state}
      const group = {
        ...value,
        visible: true,
      }
      finalState = {
        ...state,
        groups: [
          ...groups,
          group
        ]
      }
      break;
    }
    case "REMOVE_GROUP": {
      let {groups, geoJSON} = {...state}
      const idx = groups.findIndex((g) => g.id === value)
      if(idx !== -1) {
        groups.splice(idx, 1)
      }

      if(geoJSON && geoJSON.region_group) {
        let rg = {...geoJSON.region_group}
        const features = rg.features.filter((feature) => feature.id !== `REGION_GROUP/${value.toUpperCase()}`)
        geoJSON = {
          ...geoJSON,
          region_group: {
            ...rg,
            features: [...features],
          }
        }
      }

      finalState = {
        ...state,
        groups: [...groups],
        geoJSON,
      }
      break;
    }
    case "UPDATE_GROUP": {
      const {id, ids} = value
      let {groups} = state
      const gid = groups.findIndex((g) => g.id === id)
      if(gid !== -1) {
        groups = [...groups]
        groups[gid] = {
          ...groups[gid],
          ids,
        }
      }
      finalState = {
        ...state,
        groups,
        liveGroupId: "",
        selectedAreaIds: [],
      }
      break;
    }
    case "START_LOADING": {
      finalState = {
        ...state,
        loading: true
      }
      break;
    }
    case "END_LOADING": {
      finalState = {
        ...state,
        loading: false,
      }
      break;
    }
    case "SET_GROUP_VISIBILITY": {
      finalState = {
        ...state,
        displayGroups: value
      }
      break;
    }
    case "TOGGLE_GROUP_VISIBLE": {
      const {displayGroups} = {...state}
      finalState = {
        ...state,
        displayGroups: !displayGroups,
      }
      break;
    }
    case "SET_AREA_ID": {
      const { selectedAreaIds } = state
      const st = [...selectedAreaIds]
      if(st.includes(value)) {
        st.splice(st.indexOf(value), 1)
      } else {
        st.push(value)
      }
      finalState = {
        ...state,
        selectedAreaIds: st,
      }
      break;
    }
    case "REPLACE_AREA_IDS": {

      finalState = {
        ...state,
        selectedAreaIds: [...value]
      }
      break;
    }
    case "CLEAR_AREA_IDS": {
      finalState = {
        ...state,
        selectedAreaIds: [],
      }
      break;
    }
    case "SET_LIVE_GROUP_ID": {
      // Clear it
      if(!value || value.length === 0) {
        finalState = {
          ...finalState,
          selectedAreaIds: [],
          liveGroupId: ""
        }
        break;
      }

      const {groups} = state
      const group = groups.find((g) => g.id === value)
      let selectedAreaIds = []
      if(group) {
        selectedAreaIds = [...group.ids]
      }
      finalState = {
        ...state,
        liveGroupId: value,
        selectedAreaIds,
      }
      break;
    }
    case "SET_MAP_MODE": {
      finalState = {
        ...state,
        mapMode: value
      }
      break;
    }
    // case "TOGGLE_GROUP_EDIT_MODE": {
    //   finalState = {
    //     ...state,
    //     groupEditMode: !state.groupEditMode
    //   }
    //   break;
    // }
  }

  // TODO order groups by name

  return finalState
}

const defaultState = {
  loading: false,
  groups: [],
  geoJSON: {
    empty: {
      features: [{type: "Feature"}]
    }
  },
  displayGroups: false,
  selectedAreaIds: [],
  liveGroupId: "",
  groupEditMode: false,
  mapMode: "data",
}

export const GeoJSONDataProvider = ({children}) => {

  const [state, dispatchInner] = useReducer(groupReducer, defaultState)
  const {geoJSON, groups} = state
  const dispatch = (type, value) => dispatchInner({type, value})

  const territoryJSONCB = useReactiveVar(setTerritoryJSONCB)

  const loadGeoJSON = async (type = "") => {
    dispatch("START_LOADING")
    let url = ""
    if(type === "user_territory") {
      url = `/geojson/user_territory?${territoryJSONCB}`
    } else {
      url = `/geojson/gb/${type}`
    }
    try {
      const res = await axios.get(
        url,
        {
          headers: {
            Authorization: `Bearer ${getAuthToken()}`
          },
        }
      )
      dispatch("SET_GEOJSON", {[type]: res.data})
      dispatch("END_LOADING")
    } catch (e) {
      dispatch("END_LOADING")
    }
  }

  const isAlreadySelected = (id) => {
    const {groups} = state
    const flatIds = Array.from(new Set(groups.flatMap((g) => g.ids)))
    return flatIds.includes(id)
  }

  const loadUserAreas = useCallback(() => {
    // dispatch("START_LOADING")
    const {groups, geoJSON} = state
    const features = groups
      .filter((group) => group.ids && group.ids.length > 0)
      .flatMap((group) => {
        const {ids, id, name} = group
        const features = Object.keys(geoJSON)
          .map((type) => geoJSON[type])
          .flatMap((geoJSON) => geoJSON.features)
          .filter((feature) => ids.includes(feature.id))
        if(features.length > 0) {
          const fs = features.flatMap((f) => {
            if(f.geometry.type === "Polygon" || f.geometry.type === "MultiPolygon") {
              return [{
                properties: {},
                geometry: f.geometry,
                type: "Feature"
              }]
            } else if (f.geometry.type === "GeometryCollection") {
              return f.geometry.geometries.map((g) => ({
                type: "Feature",
                geometry: g,
                properties: {}
              }))
            } else {
              // We won't want points, lines, or anything else
              return []
            }
          })

          const fc = featureCollection(fs)

          const feature = dissolve(fc)
          return feature.features.map((feature) => ({
            ...feature,
            id: `REGION_GROUP/${id.toUpperCase()}`,
            properties:{
              id: `REGION_GROUP/${id.toUpperCase()}`,
              territory_id: `REGION_GROUP/${id.toUpperCase()}`,
              label: id,
              name,
            }
          }))
        } else {
          return []
        }
      })
      .filter((feature) => typeof feature !== "undefined")

    if(features.length > 0) {
      return featureCollection(features)
      // dispatch("SET_GEOJSON", {region_group: fc})
    } else {
      return {
        features: []
      }
    }
    // dispatch("END_LOADING")
  }, [geoJSON, groups])

  const getGroupGeoData = useCallback((id) => {
    const data = {
      id,
      geoJSON: {type: "FeatureCollection", features: []},
      center: undefined,
      singleShape: undefined,
      bounds: undefined
    }
    const {geoJSON, groups} = state

    const group = groups.find((g) => g.id === id)

    const jsons = []
    Object.keys(geoJSON)
      .forEach((type) => {
        geoJSON[type]
          .features
          .filter((feature) => group.ids.includes(feature.id))
          .forEach((feature) => {
            jsons.push(feature)
          })
      })
    let singleShape = {features: []}
    let center = {features: []}
    let bounds = undefined
    const fc = {
      ...featureCollection(jsons),
      properties: {
        territory_id: id,
      }
    }
    if(fc && fc.features.length > 0) {
      singleShape = {
        ...dissolve(fc),
        properties: {
          territory_id: id
        }
      }
      center = turfCenter(fc)
      bounds = bbox(fc)
    }

    return {
      ...data,
      geoJSON: fc,
      center,
      singleShape,
      bounds,
    }
  }, [state])


  const [contextValue, setContextValue] = useState({
    ...state, loadGeoJSON, getGroupGeoData, isAlreadySelected, loadUserAreas,
  })

  useEffect(() => {

    setContextValue({
      ...state,
      loadGeoJSON,
      getGroupGeoData,
      isAlreadySelected,
      loadUserAreas,
    })
  }, [state]);




  return <GeoJSONDataContext.Provider value={contextValue}>
    <GeoJSONDispatchContext.Provider value={dispatch}>
      {children}
    </GeoJSONDispatchContext.Provider>
  </GeoJSONDataContext.Provider>
}

import React from 'react'
import Segment from './Segment'
import {SideSegment} from "../index";

const CompanySize = ({ segment: inSegment, ...props }) => {
  const customOpts = [
    { name: 'Large (more than 250 employees)', value: 'large' },
    { name: 'Medium/Large', value: 'medium/large' },
    { name: 'Medium (50 to 250 employees)', value: 'medium' },
    { name: 'Small (10 to 20 employees)', value: 'small' },
    { name: 'Micro (less than 10 employees)', value: 'micro' },
    { name: 'Unknown', value: 'unknown' },
  ].filter((s) => inSegment.strings.length === 0 || inSegment.strings.includes(s.value))
  const segment = { ...inSegment, strings: [] }
  return (
    <>
      <SideSegment {...props} segment={segment} customOpts={customOpts} />
    </>
  )
}

export default CompanySize

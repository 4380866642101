import React, {useContext, useEffect, useState} from 'react'
import { useQuery } from '@apollo/client'
import { LIST_LOCATION_LISTS } from '../../graphql/queries/my-lists'
import {CBadge, CSmartTable} from '@coreui/react-pro'
import {Link, NavLink} from 'react-router-dom'
import {
  CButton,
  CCallout,
  CCard,
  CCardBody,
  CCardHeader,
  CCardTitle,
  CCollapse,
  CPopover,
} from '@coreui/react'
import DeleteList from './Components/DeleteList'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons-pro'
import LimitInformation from './Components/AddLocationsToList/LimitInformation'
import ColourChip from '../Utility/Components/ColourChip'
import JSONPretty from "react-json-pretty";
import UserSelect from "../Utility/UserSelect";
import {AuthContext} from "../../hooks/auth-context";
import SystemListInfo from "./Components/ListInfo/SystemList";
import BetaBadge from "../Utility/BetaBadge";
import FeatureFlagged from "../Utility/FeatureFlagged";

const MyLists = ({listType = "user"}) => {
  const [infoVisible, setInfoVisible] = useState(false)

  const [userFilter, setUserFilter] = useState("")
  const [filter, setFilter] = useState([])
  const [selectedUser, setSelectedUser] = useState("")
  const {user: {id: userId} = {id: ""}} = useContext(AuthContext)

  useEffect(() => {
    const f = []
    if(userFilter) {
      f.push(userFilter)
    }
    if(listType && listType.length > 0) {
      f.push({field: "list_type", value: listType})
    }
    setFilter(f)

  }, [listType, userFilter]);


  const limit = 20
  const columns = [
    {
      key: 'name',
      label: 'Name',
      filter: false,
    },
    {
      key: 'colour',
      name: 'Colour',
      _style: {
        width: '2rem',
        textAlign: 'center',
      },
      filter: false,
    },
    {
      key: 'creator',
      _style: {
        width: '10%',
        minWidth: '20rem',
      },
      filter: (values, onChange) => {
        return <UserSelect selected={selectedUser} onChange={(id) => {
          if(!id || id === "-1") {
            setUserFilter(undefined)
          } else {
            setUserFilter({field: "user_id", value: id})
          }
          setSelectedUser(id)
        }} />
      },
    },
    {
      key: 'length',
      label: 'Location Count',
      _style: {
        minWidth: '5rem',
        width: '5%',
        textAlign: 'center',
      },
      filter: false,
    },
  ]

  if(listType === "user") {
    columns.push({
      key: 'options',
      label: 'Options',
      _style: {
        maxWidth: '25%',
        width: '25%',
        minWidth: '15rem',
      },
      filter: false,
    })
  }

  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)



  const {
    loading,
    data: { ListUserLocationLists: { totalCount, entries: items } } = {
      ListUserLocationLists: { totalCount: 0, entries: [] },
    },
    refetch,
  } = useQuery(LIST_LOCATION_LISTS, {
    variables: {
      page,
      limit,
      filter,
    },
    fetchPolicy: "network-only",
    onCompleted({ ListUserLocationLists: { totalCount, pageInfo, entries } }) {
      // setTotalCount(totalCount)
      // setItems(entries)
    },
  })

  useEffect(() => {
    let p = 1
    if (totalCount > 0) {
      p = Math.ceil(totalCount / limit)
      if (p < 1) {
        p = 1
      }
    }
    setPages(p)
  }, [totalCount, limit])

  return (
    <>
      <CCard>
        <CCardHeader className={"d-flex"}>
          <CCardTitle>
            {listType === "standard" ? "Client Status" : "Team Lists"}
            {listType === "user" && <CPopover content={'Click for more information'} trigger={'hover'}>
              <span style={{ cursor: 'pointer' }} className={"ms-1"} onClick={() => setInfoVisible(!infoVisible)}>
                <CIcon icon={cilInfo}></CIcon>
              </span>
            </CPopover>}
          </CCardTitle>
          {/*<FeatureFlagged flag={"standard-lists"}>*/}

          {/*  <NavLink to={"/lists"} end className={"card-tab"}>Team Lists</NavLink>*/}
          {/*  <NavLink to={"/lists/standard"} className={"card-tab"}>Client Status <BetaBadge /></NavLink>*/}
          {/*</FeatureFlagged>*/}

        </CCardHeader>
        <CCardBody>

          {listType === "user" && <CCollapse visible={infoVisible}>
            <CCallout color={'info'}>
              {listType === "user" && <LimitInformation />}
              <CButton color={'primary'} size={'sm'} onClick={() => setInfoVisible(false)}>
                OK
              </CButton>
            </CCallout>
          </CCollapse>}

          {listType === "standard" && <SystemListInfo />}

          {listType === "user" && <Link to={'create'} className={'btn btn-primary'}>
            Create New List
          </Link>}

          <CSmartTable
            key={`list-table-${listType}`}
            loading={loading}
            className={'mt-3'}
            itemsPerPage={limit}
            columns={columns}
            columnFilter={listType === "user" ? { external: true }: false}
            pagination={pages > 1}
            paginationProps={{
              onActivePageChange: setPage,
              activePage: page,
              pages,
            }}
            items={items}
            scopedColumns={{
              colour: (item) => (
                <td>
                  <ColourChip colour={item.colour} center />
                </td>
              ),
              creator: (item) => <td>{item.user.name}</td>,
              name: (item) => (
                <td>
                  <Link to={`view/${item.id}`}>{item.name}</Link>
                </td>
              ),
              length: (item) => (
                <td style={{ textAlign: 'center' }}>{item.locations.totalCount ?? '0'}</td>
              ),
              options: (item) => (
                <td align={'right'}>

                  {item.user.id === userId && <DeleteList id={item.id} onDelete={() => refetch()} />}
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default MyLists

import React, { useState } from 'react'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import { READ_TERRITORY, UPDATE_TERRITORY } from '../../../graphql/queries/territory'
import { useNavigate, useParams } from 'react-router-dom'
import { STATUS } from '../../../tools/const'
import { CAlert, CButton, CCard, CCardBody, CCardHeader, CCardTitle } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilCheckCircle, cilWarning } from '@coreui/icons-pro'
import TerritoryForm from './Components/TerritoryForm'
import {setTerritoryJSONCB} from "../../../graphql/policies/search";
import {fiveDigitRand} from "../../../tools/random";

const EditTerritory = () => {
  const [status, setStatus] = useState(STATUS.WAITING)
  const { territoryId } = useParams()
  const client = useApolloClient()
  const nav = useNavigate()

  const {
    data: { ReadTerritory: territory } = { ReadTerritory: {} },
    error,
    loading: dataLoading,
  } = useQuery(READ_TERRITORY, {
    variables: {
      id: territoryId,
    },
  })

  const [updateTerritory, { loading: updateLoading }] = useMutation(UPDATE_TERRITORY, {
    onCompleted({ UpdateTerritory: { id } }) {
      if (id) {
        setStatus(STATUS.SUCCESS)
        setTerritoryJSONCB(fiveDigitRand())
        client.cache.evict({ fieldName: 'ListTerritory' })
        client.cache.modify({
          id: 'ROOT_QUERY',
          fields: {
            search(current) {
              return {
                ...current,
                reducedSegments: undefined,
              }
            },
          },
        })
        client.cache.gc()
        window.scrollTo(0, 0)
      }
    },
    onError(e) {
      setStatus(STATUS.FAIL)
      window.scrollTo(0, 0)
    },
  })

  if (error) {
    return <CAlert color={'danger'}>{error.message}</CAlert>
  }

  return (
    <>
      {status === STATUS.SUCCESS && (
        <CAlert color={'success'} className={'d-flex align-items-center'}>
          <CIcon width={24} height={24} icon={cilCheckCircle} className={'flex-shrink-0 me-2'} />
          Territory updated successfully!{' '}
          <CButton color={"primary"} onClick={() => nav(-1)} size={'sm'} className={'mx-1'}>
            Return to List
          </CButton>
        </CAlert>
      )}
      {status === STATUS.FAIL && (
        <CAlert color={'danger'} className={'d-flex align-items-center'}>
          <CIcon width={24} height={24} icon={cilWarning} className={'flex-shrink-0 me-2'} />
          Territory update failed.
        </CAlert>
      )}
      <CCard>
        <CCardHeader>
          <CCardTitle>Create Territory</CCardTitle>
        </CCardHeader>
        <CCardBody>
          <TerritoryForm
            territory={territory}
            loading={dataLoading || updateLoading}
            onComplete={async (variables) =>
              updateTerritory({ variables: { id: territoryId, ...variables } })
            }
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default EditTerritory

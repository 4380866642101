import React, { useCallback, useEffect, useState } from 'react'
import {
  CBadge,
  CCol,
  CPlaceholder,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from '@coreui/react'
import { Link } from 'react-router-dom'
import { CSmartPagination } from '@coreui/react-pro'
import Sort from '../SearchBar/Components/Sort'
import CompanySize from './Components/CompanySize'
import ClinicLists from '../MyLists/Components/ClinicLists'
import NameSearch from '../SearchBar/Components/NameSearch'
import SocialInfo from './Components/SocialInfo'
import Features from './Components/Features'
import BetaBadge from '../Utility/BetaBadge'
import FeatureFlagged from '../Utility/FeatureFlagged'
import SetClientStatus from './Components/ClientStatus/SetClientStatus'
import FilterClientStatus from './Components/ClientStatus/FilterClientStatus'

const ClinicSearch = ({
  showLink = true,
  showAddToSearch = false,
  showFilterAdvice = false,
  filter = [],
  sort = [],
  entries = [],
  loading = true,
  totalCount = 0,
  page = 1,
  pageSize = 0,
  onFilterChange = (filters) => void 0,
  onPageChange = (page) => void 0,
  onPageSizeChange = (pageSize) => void 0,
  onSortChange = (field, direction) => void 0,
  refetch = async () => void 0,
  hideInlineFilters = false,
  allowSort = false,
  selections = {},
}) => {
  const [totalPages, setTotalPages] = useState(0)

  useEffect(() => {
    if (totalCount > 0) {
      setTotalPages(Math.ceil(totalCount / pageSize))
    } else {
      setTotalPages(0)
    }
  }, [totalCount, pageSize])

  const setPage = useCallback(
    (p) => {
      if (!loading && totalPages > 0) {
        onPageChange(p)
      }
    },
    [page, onPageChange, totalPages, loading],
  )

  useEffect(() => {
    onPageSizeChange(10)
  }, [])

  const columns = [
    { key: 'name', label: 'Location Name' },
    // {key:}
  ]

  return (
    <div>
      <CRow className={'ps-1'}>
        <CCol sm={{ span: 7 }} xxl={4} style={{ marginLeft: 'auto' }}>
          <p style={{ fontSize: '1.3rem' }}>
            {totalCount >= 10000 && 'More Than'} <strong>{totalCount}</strong> Locations Found
          </p>
          {showFilterAdvice && totalCount >= 6000 && (
            <div className={'pb-3'}>
              Your current search contains a lot of results. You can narrow down your search by
              selecting items from the filter buttons above, or by entering a postcode.
            </div>
          )}
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <CSmartPagination
            pages={totalPages}
            activePage={page}
            onActivePageChange={setPage}
            align={'center'}
          />
        </CCol>
      </CRow>

      <CTable align={'middle'} className={'border list-search__table'} hover small>
        <CTableHead color="light" className={'position-relative'}>
          <CTableRow align={'top'}>
            <CTableHeaderCell style={{ width: '25%', minWidth: '20rem' }}>
              Name{' '}
              {allowSort && (
                <Sort
                  field={'name'}
                  direction={() => {
                    const so = sort.find((s) => s.field === 'name')
                    if (so) {
                      return so.direction
                    } else {
                      return ''
                    }
                  }}
                  onChange={onSortChange}
                />
              )}
            </CTableHeaderCell>
            <FeatureFlagged flag={'standard-lists'}>
              <CTableHeaderCell>
                Client Status
              </CTableHeaderCell>
            </FeatureFlagged>
            <CTableHeaderCell style={{ minWidth: '8rem' }}>Company Size</CTableHeaderCell>
            <CTableHeaderCell style={{ minWidth: '10rem' }}>Contact Methods</CTableHeaderCell>
            <CTableHeaderCell style={{ minWidth: '8rem' }}>Good to Know</CTableHeaderCell>
          </CTableRow>
          {!hideInlineFilters && (
            <CTableRow>
              <CTableDataCell>
                <NameSearch />
              </CTableDataCell>
              <FeatureFlagged flag={'standard-lists'}>

              <CTableDataCell className={'d-flex flex-column'}>
                <FilterClientStatus selections={selections} />
              </CTableDataCell>
              </FeatureFlagged>
              <CTableDataCell colSpan={4}></CTableDataCell>
            </CTableRow>
          )}
        </CTableHead>

        <CTableBody className={'position-relative'}>
          <>
            {!loading && totalPages === 0 && (
              <tr>
                <td colSpan={4} className={'p-2 text-center fst-italic'}>
                  No Clinics Found
                </td>
              </tr>
            )}
            {loading &&
              [...new Array(10)].map((i, id) => (
                <React.Fragment key={`loading-${id}`}>
                  <CTableRow>
                    <CTableDataCell>
                      <CPlaceholder animation={'wave'}>
                        <CPlaceholder xs={9} />
                        <br />
                        <CPlaceholder xs={5} size={'sm'} />
                      </CPlaceholder>
                    </CTableDataCell>

                    <CTableDataCell>
                      <CPlaceholder animation={'wave'}>
                        <CPlaceholder xs={10} />
                      </CPlaceholder>
                    </CTableDataCell>
                    <CTableDataCell>
                      <CPlaceholder animation={'wave'}>
                        <CPlaceholder xs={10} />
                        <CPlaceholder xs={10} />
                      </CPlaceholder>
                    </CTableDataCell>
                    <CTableDataCell></CTableDataCell>
                  </CTableRow>
                </React.Fragment>
              ))}
          </>

          <>
            {!loading &&
              entries.map((item, index) => (
                <CTableRow
                  key={`${item.external_id}-${index}`}
                  className={`clinic-list-row${item.is_new ? ' new' : ''}`}
                >
                  <CTableDataCell>
                    <div>
                      {showLink && <Link to={'/clinic/' + item.external_id}>{item.name}</Link>}
                      {!showLink && item.name}{' '}
                      {item.is_new && (
                        <CBadge size={'sm'} color={'info'}>
                          New
                        </CBadge>
                      )}
                    </div>
                    <div className="small text-medium-emphasis">
                      {item.address && item.address.line1} &nbsp;{' '}
                      {item.address && item.address.postal_code}
                    </div>
                    {showAddToSearch && (
                      <ClinicLists
                        lists={item.lists}
                        clinicId={item.external_id}
                        onChange={async () => refetch()}
                      />
                    )}
                  </CTableDataCell>
                  <FeatureFlagged flag={'standard-lists'}>
                    <CTableDataCell>
                      <SetClientStatus
                        locked={hideInlineFilters}
                        lists={item.lists}
                        locationId={item.external_id}
                        onChange={() => {
                          refetch()
                        }}
                      />
                    </CTableDataCell>
                  </FeatureFlagged>
                  <CTableDataCell>
                    <CompanySize size={item.company_size} />
                  </CTableDataCell>
                  <CTableDataCell>
                    <SocialInfo
                      socialData={item.social_data}
                      phone={item.phone_number ?? ''}
                      id={item.external_id}
                    />
                  </CTableDataCell>
                  <CTableDataCell>
                    <Features clinic={item} />
                  </CTableDataCell>
                </CTableRow>
              ))}
          </>
        </CTableBody>
      </CTable>

      <CRow>
        <CSmartPagination
          pages={totalPages}
          activePage={page}
          onActivePageChange={setPage}
          align={'center'}
        />
      </CRow>
    </div>
  )
}

export default ClinicSearch

import {gql} from "@apollo/client";

export const LIST_LOCATION_LISTS = gql`
  query ListLocationLists($page: Int! $limit: Int! $filter: [JSONObject!]) {
    ListUserLocationLists(search: {page: $page limit: $limit filter: $filter }) {
      entries {
        id name colour list_class list_type user {
          id name
        } location_ids locations {
          totalCount
        }
      }
      pageInfo
      totalCount
    }
  }
`

export const VIEW_LOCATION_LIST = gql`
  query ViewLocationList($id: String! $page: Int! $limit: Int!) {
    UserLocationList(id: $id search: {}) {
      id name colour list_class list_type user {
        id name
      } locations(params: {page: $page limit: $limit}) {
        entries {
          external_id name phone_number address {
            line1 line2 locality postal_code region
          } contacts {
            name activity
          }
          hcp_contacts
          social_data
          visible
        }
        pageInfo {
          currentPage
        }
        totalCount
      }
    }
  }
`

export const CREATE_LOCATION_LIST = gql`
  mutation CreateLocationList($name: String! $locations: [String!]!) {
    CreateUserLocationList(name: $name locations: $locations) {
      id name locations {
        totalCount
      }
    }
  }
`

export const ADD_LOCATIONS_TO_LIST = gql`
  mutation AddLocationsToList($id: String! $locations: [String!]!) {
    AddLocationsToList(locations: $locations id: $id) {
      id name locations {
        totalCount
      }
    }
  }
`

export const REMOVE_LOCATIONS_FROM_LIST = gql`
  mutation RemoveLocationsFromList($id: String! $locations: [String!]!) {
    RemoveLocationsFromList(locations: $locations id: $id) {
      id name locations {
        entries {
            external_id name
        }
      }
    }
  }
`

export const DELETE_LOCATION_LIST = gql`
  mutation DeleteLocationList($id: ID!) {
    DeleteUserLocationList(id: $id) {
      id success
    }
  }
`

export const UPDATE_LOCATION_LIST = gql`
  mutation UpdateLocationList($id: ID! $name: String $colour: String) {
    UpdateUserLocationList(id: $id name: $name, colour: $colour ) {
      id name colour
    }
  }
`

export const ADD_TO_SYSTEMS_LIST = gql`
    mutation AddLocationsToSystemList($listClass: String! $locations: [String!]!) {
      AddLocationsToSystemList(listClass: $listClass locations: $locations) {
        id
      }
    }
`

export const REMOVE_FROM_ALL_SYSTEM_LISTS = gql`
  mutation RemoveFromAllSystemLists($locations: [String!]!) {
    RemoveLocationsFromSystemLists(locations: $locations)
  }
`

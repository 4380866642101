// temporary
const SETTINGS = {
  CSV_MAX_EXPORT: 500
}

export const getSubscriptionSetting = (setting) => {
  if(SETTINGS.hasOwnProperty(setting)) {
    return SETTINGS[setting]
  } else {
    return "SETTING_NOT_FOUND"
  }
}

import React, {useContext, useEffect, useState} from "react";
import {CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle} from "@coreui/react-pro";
import {CDropdownDivider} from "@coreui/react";
import {AuthContext} from "../../../../hooks/auth-context";
import ColourChip from "../../../Utility/Components/ColourChip";
const defaultStatus = {
  name: 'Client Status',
  color: 'var(--cui-primary)',
  slug: 'none',
  list_class: 'none',
}

const SelectClientStatus = ({selected, onComplete = (slug = "") => void 0, onClear = () => void 0}) => {
  const {subscription: {settings: {STANDARD_LISTS = []}}} = useContext(AuthContext)


  const showName = (listClass) => {
    const found = STANDARD_LISTS.find((l) => l.slug === listClass)
    if(found) {
      return found.name
    } else {
      if(listClass === "none") {
        return defaultStatus.name
      }
      return listClass
    }
  }

  const showSlug = (listClass) => {
    const found = STANDARD_LISTS.find((l) => l.slug === listClass)
    if(found) {
      return found.slug
    } else {
      if(listClass === "none") {
        return "none"
      } else {
        return 'default'
      }
    }
  }
  const showColor = (listClass) => {
    const found = STANDARD_LISTS.find((l) => l.slug === listClass)
    if(found) {
      return found.color
    } else {
      if(listClass === "none") {
        return defaultStatus.color
      } else {
        return 'var(--cui-primary)';
      }
    }
  }

  return (
    <>
      <CDropdown>
        <CDropdownToggle
          className={'standard-list__toggle btn-status'}
          color={`status-${showSlug(selected)}`}
          style={{ '--st-list-color': showColor(selected) }}
          size={'sm'}
        >
          <span>{showName(selected)}</span>
        </CDropdownToggle>
        <CDropdownMenu>
          <CDropdownItem
            onClick={() => {
              onClear()
            }}
          >
            Clear
          </CDropdownItem>
          <CDropdownDivider />
          <>
            {STANDARD_LISTS.map((item) => {
              return (
                <CDropdownItem
                  key={`${item.slug}`}
                  active={selected === item.slug}
                  onClick={() => {
                    onComplete(item.slug)
                  }}
                  className={"d-flex align-items-center"}
                >
                  <ColourChip colour={item.color} size={"1rem"}  />
                  {item.name}
                </CDropdownItem>
              )
            })}
          </>
        </CDropdownMenu>
      </CDropdown>
    </>
  )
}

export default SelectClientStatus

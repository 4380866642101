import {gql} from "@apollo/client";
import {CLINIC_FRAGMENT} from "./clinic";

export const CLINIC_SEARCH = gql`
  query ClinicSearch($after: JSON $terms: [CSearchTerm!] $limit: Int = 20 $page: Int = 2 $sort: [Sort!] $aggregateReturns: [String!] $minimal: Boolean = false $agOnly: Boolean = false) {
    search(search: {after: $after terms: $terms limit: $limit page: $page sort: $sort aggregateReturns: $aggregateReturns}) {
      entries @skip(if: $agOnly) {
        external_id
        name @skip(if: $minimal)
        address @skip(if: $minimal) {
          line1 postal_code
        }
        phone_number @skip(if: $minimal)
        is_new
        location {
          lat lon
        }
        company_size @skip(if: $minimal)
        brands @skip(if: $minimal)
        treatment_categories @skip(if: $minimal)
        lists {
          id
          colour
          name @skip(if: $minimal)
          list_type
          list_class
        }
        social_data @skip(if: $minimal)
        annotationCount @skip(if: $minimal)
      }
      totalCount
      pageInfo {
        pageSize currentPage after
      }
      reducedSegments
      selections
      aggregations

    }
  }
`

export const CLINIC_MAP_SEARCH = gql`
  query MapSearch($after: JSON $terms: [CSearchTerm!] $limit: Int! $aggregateReturns: [String!]  ) {
    mapSearch(search: {after: $after terms: $terms limit: $limit aggregateReturns: $aggregateReturns}) {
      totalCount
      entries {
        external_id
        is_new
        location {
          lat lon
        }
        lists {
          id colour
        }
      }
      selections
      pageInfo {
        pageSize
      }
      aggregations
    }
  }
`

export const CLINIC_AGGREGATE_SEARCH = gql`
  query AggregateSearch($terms: [CSearchTerm!], $aggregateReturns: [String!]) {
    aggregateOnlySearch(search: {terms: $terms, aggregateReturns: $aggregateReturns}) {
      totalCount
      aggregations
      selections
    }
  }
`

export const LOCATION_ID_SEARCH = gql`
  query LocationIdSearch($terms: [CSearchTerm!] $limit: Int, $page: Int = 1 $sort: [Sort!] = []) {
    search(search: {terms: $terms limit: $limit page: $page sort: $sort}) {
      totalCount entries {
        external_id
      }
    }
  }
`

export const SEARCH_BY_ID = gql`
  ${CLINIC_FRAGMENT}
  query SearchById($id: String! $params: CSearchParams!) {
    searchByID(id: $id params: $params) {
      entries {
        ...ClinicFragment
      }
      totalCount
      pageInfo {
          pageSize currentPage after
      }
    }
  }
`


import React from 'react'
import AddLocationsToList from '../AddLocationsToList'
import { useMutation } from '@apollo/client'
import { REMOVE_LOCATIONS_FROM_LIST } from '../../../../graphql/queries/my-lists'
import ListTags from '../ListTags'
import ClinicListButton from './ClinicListButton'

const ClinicLists = ({ lists = [], clinicId, onChange = async () => void 0 }) => {
  const [removeClinic] = useMutation(REMOVE_LOCATIONS_FROM_LIST, {
    onCompleted() {
      onChange()
    },
  })

  return (
    <div className={'mb-3 '}>
      <ListTags

        lists={lists}
        onRemove={(id) => removeClinic({ variables: { id, locations: [clinicId] } })}
      />
      <AddLocationsToList locations={[clinicId]} onComplete={onChange}>
        <ClinicListButton />
      </AddLocationsToList>
    </div>
  )
}

export default ClinicLists

import React, { useEffect, useState } from 'react'
import { useApolloClient, useMutation, useQuery } from '@apollo/client'
import {
  ADD_LOCATIONS_TO_LIST,
  LIST_LOCATION_LISTS,
  REMOVE_LOCATIONS_FROM_LIST,
} from '../../../../graphql/queries/my-lists'
import { CSmartTable } from '@coreui/react-pro'
import { CButton } from '@coreui/react'
import { getSubscriptionSetting } from '../../../../tools/subscription'
import MessageModal from '../../../Utility/Components/MessageModal'
import { useEventBus } from '../../../../hooks/use-event-bus'
import ColourChip from '../../../Utility/Components/ColourChip'

const Lists = ({
  locations = [],
  onComplete = async () => void 0,
  onCancel,
  onError = (e) => void 0,
  onlyAdd = false,
  showModal = false,
  exclude = [],
  userOnly,
  listType,
}) => {
  const { cache } = useApolloClient()
  const [message, setMessage] = useState({})
  const [filter, setFilter] = useState([])

  useEffect(() => {
    let f = []

    if(listType) {
      f = [{field: "list_type", value: listType}]
    }

    if (exclude && exclude.length > 0) {
      f = [
        ...f,
        ...exclude.map((value) => ({
          field: 'location_id',
          value,
        })),

      ]
    }

    if(f.length > 0) {
      setFilter(f)
    }
  }, [])

  const { emitToast } = useEventBus('toasts')

  const columns = [
    { key: 'colour' },
    {
      key: 'name',
    },
    {
      key: 'length',
    },
    { key: 'options' },
  ]
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(0)
  const limit = 5
  const CSV_MAX_EXPORT = Number(getSubscriptionSetting('CSV_MAX_EXPORT'))

  const [addToList, { error }] = useMutation(ADD_LOCATIONS_TO_LIST, {
    onCompleted({ AddLocationsToList: { name } }) {
      emitToast({
        title: 'Locations added!',
        message: <>You have added locations to "{name}"!</>,
      })
      onComplete()

      cache.evict({ fieldName: '' })
    },
    onError({ name, extraInfo, message, graphQLErrors }) {
      if (onError && typeof onError === 'function') {
        onError({ name, extraInfo, message, graphQLErrors })
      }
      console.warn({ name, extraInfo, message, graphQLErrors })
    },
  })

  const [removeFromList] = useMutation(REMOVE_LOCATIONS_FROM_LIST, {
    onCompleted({ RemoveLocationsFromList: { name } }) {
      let title = 'Locations Removed'
      let message = <>You have removed locations from "{name}"</>

      emitToast({
        title,
        message,
      })
      onComplete()
    },
  })

  const {
    data: { ListUserLocationLists: { entries, totalCount } } = {
      ListUserLocationLists: { entries: [], totalCount: 0 },
    },
  } = useQuery(LIST_LOCATION_LISTS, {
    variables: { page, limit, filter },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    let p = 1
    if (totalCount > 0) {
      p = Math.ceil(totalCount / limit)
      if (p < 1) {
        p = 1
      }
    }
    setPages(p)
  }, [totalCount])

  return (
    <>
      <CSmartTable
        className={'mt-3'}
        columns={columns}
        items={entries}
        pagination={pages > 1}
        paginationProps={{
          align: "center",
          activePage: page,
          onActivePageChange: (p) => setPage(p),
          pages,
        }}
        scopedColumns={{
          colour: (item) => (
            <td>
              <ColourChip colour={item.colour} size={'1rem'} center />{' '}
            </td>
          ),
          length: (item) => <td>{item.locations.totalCount ?? 0}</td>,
          options: (item) => {
            // if locations is greater than 1 we're mass adding
            let showAdd = false
            let showRemove = false
            const total = item.locations.totalCount ?? 0

            if (locations.length > 1) {
              // set up mass adding check
              if (total < CSV_MAX_EXPORT) {
                showAdd = true
              }
            } else {
              const found = item.location_ids.findIndex((l) => locations.includes(l))
              if (found === -1) {
                if (total < CSV_MAX_EXPORT) {
                  showAdd = true
                }
              } else {
                showRemove = true
              }
            }

            if (onlyAdd) {
              showRemove = false
            }

            return (
              <td align={'right'}>
                {showAdd && (
                  <CButton
                    color={'primary'}
                    size={'sm'}
                    onClick={() => addToList({ variables: { id: item.id, locations } })}
                  >
                    Add
                  </CButton>
                )}{' '}
                {showRemove && (
                  <CButton
                    color={'primary'}
                    size={'sm'}
                    onClick={() => removeFromList({ variables: { id: item.id, locations } })}
                  >
                    Remove
                  </CButton>
                )}{' '}
              </td>
            )
          },
        }}
      />
      <MessageModal {...message} />
    </>
  )
}

export default Lists

import CIcon from "@coreui/icons-react";
import {
  cilAt,
  cilBook,
  cilCalculator,
  cilDescription,
  cilGroup,
  cilList,
  cilPuzzle,
  cilSearch
} from "@coreui/icons-pro";
import React from "react";
import {isFeatureFlagged} from "../../../tools/feature-flag";

const nav = [
  {
    name: 'Dashboard',
    to: '/',
    icon: <CIcon icon={cilCalculator} customClassName={'nav-icon'} />
  },
  // {
  //   name: 'Planning',
  //   icon: <CIcon icon={cilDescription} customClassName={'nav-icon'} />,
  //   to: '/planning',
  //   roles: ['planning_analysis', 'territory_manage']
  // },
  {
    name: "Team Lists",
    to:"/lists",
    icon: <CIcon icon={cilList} customClassName={'nav-icon'} />,
  },
  {
    name: "Glossary",
    to: "/glossary",
    icon: <CIcon icon={cilBook}  customClassName={'nav-icon'} />,

  },
  {
    name: "My Account",
    to: "/myaccount",
    icon: <CIcon icon={cilAt}  customClassName={'nav-icon'} />,

  },
  {
    name: "Admin",
    to: "/manage",
    icon: <CIcon icon={cilDescription} customClassName={'nav-icon'} />,
    children: [
      {
        name: "Manage Users",
        to: "/manage/users",
        roles: ['sub_user_manage', 'sub_user_view'],
        icon: <CIcon icon={cilGroup} customClassName={'nav-icon'} />,

      },
      {
        name: "Build User Searches",
        to: "/manage/searches",
        roles: ['sub_search_manage'],
        icon: <CIcon icon={cilSearch} customClassName={'nav-icon'} />,
      },
    ],
  },
  {
    name: 'Build Territories',
    to: '/manage/territories',
    icon: <CIcon icon={cilPuzzle} customClassName={'nav-icon'} />,
  },
]

export default nav

import { CFormSwitch, CPopover } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilInfo } from '@coreui/icons-pro'
import { useEffect, useState } from 'react'

const ToggleSegment = ({ segment, search, segmentSearch, onChange }) => {
  const [isSet, setIsSet] = useState(false)
  useEffect(() => {
    const f = Object.keys(search).filter((k) => k.includes(segment.name))
    if (f.length > 0 && search[f].length > 0) {
      setIsSet(true)
    } else {
      setIsSet(false)
    }
  }, [search])
  let label = segment.title
  if (segment?.help) {
    label = (
      <>
        {label}{' '}
        <CPopover content={segment.help} trigger={['hover', 'focus']}>
          <span>
            <CIcon icon={cilInfo} size={'sm'} style={{ cursor: 'pointer' }} />
          </span>
        </CPopover>{' '}
      </>
    )
  }
  return (
    <>
      <div className={'search-segment-container d-flex'}>
        <CFormSwitch
          size={'lg'}
          onChange={() => onChange(`${segment.name}:or`, 'true')}
          label={label}
          checked={isSet}
        />
      </div>
    </>
  )
}

export default ToggleSegment

import React, { useContext, useEffect, useState } from 'react'
import FeatureFlagged from '../../../Utility/FeatureFlagged'
import SelectClientStatus from './SelectClientStatus'
import { SearchContext, SearchDispatchContext } from '../../../../hooks/search-context'

const FilterClientStatus = ({}) => {
  const dispatch = useContext(SearchDispatchContext)
  const { search } = useContext(SearchContext)
  const [selected, setSelected] = useState('none')
  useEffect(() => {
    const { client_status = [] } = search
    if (client_status && client_status.length > 0) {
      setSelected(client_status[0])
    } else {
      setSelected('none')
    }
  }, [search])

  return (
      <SelectClientStatus
        selected={selected}
        onClear={() => dispatch('REMOVE_PARAM', 'client_status')}
        onComplete={(value) => dispatch("REPLACE_PARAM", {name: "client_status", value})}
      />
  )
}

export default FilterClientStatus

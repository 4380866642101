import React, {useEffect, useState} from "react";
import {useMutation} from "@apollo/client";
import {ADD_TO_SYSTEMS_LIST, REMOVE_FROM_ALL_SYSTEM_LISTS} from "../../../../graphql/queries/my-lists";
import {useEventBus} from "../../../../hooks/use-event-bus";
import {CButton} from "@coreui/react-pro";
import SelectClientStatus from "./SelectClientStatus";

const defaultStatus = {
  name: 'Client Status',
  color: 'var(--cui-primary)',
  slug: 'none',
  list_class: 'none',
}

const SetClientStatus = ({locked = false, locationId, lists, onChange}) => {

  const {emit} = useEventBus('toasts')

  const [status, setStatus] = useState(defaultStatus)


  useEffect(() => {
    const selected = lists.filter((list) => list.list_type === "standard")
    if(selected.length > 0) {
      setStatus(selected[0])
    } else {
      setStatus(defaultStatus)
    }
  }, [lists]);

  const [addToList, {loading}] = useMutation(
    ADD_TO_SYSTEMS_LIST,
    {
      onCompleted() {
        onChange()
      },
      onError(e) {
        emit({message: `Adding to list failed: ${e.message}`})
      }
    }
  )

  const [removeFromAll, {loading: rLoading}] = useMutation(
    REMOVE_FROM_ALL_SYSTEM_LISTS,
    {
      onCompleted() {
        onChange()
      },
      onError(e) {
        emit({message: "Failed to clear location status."})
      },
      variables: {
        locations: [locationId]
      }
    }
  )

  if (locked) {
    return (
      <CButton
        className={'standard-list__toggle btn-status'}
        color={`status-${showSlug(status.list_class)}`}
        style={{ '--st-list-color': showColor(status.list_class), cursor: "default" }}
        size={'sm'}
      >
        <span>{showName(status.name)}</span>
      </CButton>
    )
  }

  return <SelectClientStatus selected={status.list_class} onClear={() => removeFromAll()} onComplete={(slug) => void addToList({ variables: { listClass: slug, locations: [locationId] } })} />
}

export default SetClientStatus

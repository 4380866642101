import {createContext, useCallback, useEffect, useState} from "react";
import {useLazyQuery, useReactiveVar} from "@apollo/client";
import {CHECK_AUTH, MY_ACCOUNT} from "../graphql/queries/my_account";
import {account as legacyAccount, AUTH_STATE, authError, authState as legacyAuthState} from "../graphql/policies/auth";
import {clearAuthToken} from "../auth";
import {permissionsAllow} from "../tools/permissions";

export const AuthContext = createContext({
  userRolesPermit: () => false,
  userRolesDeny: () => true,
  anyAllow: () => false,
  logOut: async () => void 0,
  logIn: async () => void 0,
  user: {permissions: {allow: [], deny: []}, id: ""},
  subscription: {settings: {}},
  loggedIn: false,
  authState: AUTH_STATE.WAITING,
  getSetting: (name = "") => undefined
})

export const AuthProvider = ({children}) => {
  const authState = useReactiveVar(legacyAuthState)
  const [loggedIn, setLoggedIn] = useState(false)
  const [subscription, setSubscription] = useState({settings: {}})
  const [user, setUser] = useState({permissions: {allow: [], deny: []}})

  const [getAccount, { loading, error }] = useLazyQuery(MY_ACCOUNT, {
    fetchPolicy: "network-only",
    onCompleted({ myAccount: { subscription, user } }) {
      legacyAccount(user)
      setSubscription(subscription)
      setUser(user)
    },
  })

  const [checkAuth] = useLazyQuery(CHECK_AUTH, {
    onCompleted: ({ CheckAuth }) => {
      if (CheckAuth) {
        legacyAuthState(AUTH_STATE.LOGGED_IN)
        authError("")
        // getAccount()
      } else {
        clearAuthToken()
        legacyAuthState(AUTH_STATE.LOGGED_OUT)
        authError("You have been logged out. Please log in again to continue")
      }
    }
  })

  useEffect(() => {
    if(authState === AUTH_STATE.LOGGED_IN) {
      getAccount()
    }
  }, [authState]);

  // Check and initialise the auth
  // TODO Don't run this if we haven't got a token?
  useEffect(() => {
    checkAuth()
  }, [])

  const userRolesPermit = useCallback((roles = []) => {
    const r = roles.filter((role) => role.length > 0)
    if(r.length === 0) {
      return true
    }
    return permissionsAllow(user.permissions, r)
  }, [user])

  const userRolesDeny = useCallback((roles = []) => {
    const {permissions: {deny = []}} = user
    const denied = deny.filter((p) => roles.includes(p))
    return denied.length > 0
  }, [user])

  const anyAllow = useCallback((roles = []) => {
    if(roles.length === 0) {
      return true
    }
    const {permissions: {allow, deny}} = user
    const any = roles
      .filter((role) => !deny.includes(role))
      .filter((role) => allow.includes(role))

    return any.length > 0
  }, [user])

  const getSetting = (name) => {
    const {settings} = subscription
    if(settings.hasOwnProperty(name)) {
      return settings[name]
    } else {
      return undefined
    }
  }

  const contextValue = {
    logOut: async () => {},
    logIn: async () => {

    },
    userRolesPermit,
    userRolesDeny,
    anyAllow,
    user,
    subscription,
    loggedIn,
    authState,
    getSetting,
  }

  return <AuthContext.Provider value={contextValue}>
    {children}
  </AuthContext.Provider>
}

import React from 'react'
import { CImage } from '@coreui/react'
import ABPI from '../assets/ABPI_Logo_Member_WHITE.png'
import BHBIA from '../assets/BHBIA.png'
import MRS from '../assets/Rare_MRS.png'

const AppFooter = ({ collapse = false }) => {
  if (collapse) {
    return <></>
  }
  return (
    <footer className={'monitor-footer mt-4'}>
      <h6>Rare: Monitor from Rare: Group</h6>
      <div className={'monitor-footer-first'}>
        <div>
          <address>
            <p>
              133 Whitechapel Street
              <br />
              London
              <br />
              E1 7QA
            </p>
            <p>+44 (0) 207 859 4627</p>
            <span>hello@rare.consulting</span>
          </address>
        </div>

        <div className={'d-flex justify-content-lg-between gap-lg-5  flex-wrap mb-lg-1 mb-5'}>
          <span><CImage src={MRS} height={60} /></span>
          <span><CImage src={ABPI} height={60} /></span>
          <span><CImage src={BHBIA} height={60} /></span>
        </div>
      </div>
      <div className={'monitor-footer-second'}>
        <p>
          <a href={'https://www.rare.consulting/privacy-notice'}>Privacy Notice</a>
        </p>
        <p>
          <a
            href={'https://www.rare.consulting/anti-slavery-and-human-trafficking-policy-statement'}
          >
            Anti-slavery and human trafficking policy statement{' '}
          </a>
        </p>
      </div>
      <span>&copy; 2023 Rare Consulting</span>
    </footer>
  )
}

export default React.memo(AppFooter)

import React, {useState} from 'react'
import {CAlert, CButton, CCard, CCardBody, CCardHeader, CCardTitle, CCol, CRow, CSpinner} from '@coreui/react'
import Form, { useForm } from 'rc-field-form'
import {FormField, Input} from "../Form";
import {useApolloClient, useMutation} from "@apollo/client";
import {CREATE_LOCATION_LIST} from "../../graphql/queries/my-lists";
import {STATUS} from "../../tools/const";
import CIcon from "@coreui/icons-react";
import {cilCheckCircle, cilWarning} from "@coreui/icons-pro";
import {useNavigate} from "react-router-dom";
import ListForm from "./Components/ListForm";

const CreateList = () => {
  const [status, setStatus] = useState(STATUS.WAITING)
  const client = useApolloClient()
  const nav = useNavigate()
  const [disabled, setDisabled] = useState(false)

  const [createList, {loading}] = useMutation(
    CREATE_LOCATION_LIST,
    {
      onCompleted({CreateUserLocationList: {id}}) {
        if(id) {
          setStatus(STATUS.SUCCESS)
          client.cache.evict({ fieldName: 'ListUserLocationLists' })
          client.cache.gc()
          setDisabled(true)
        }
      },
      onError() {
        setStatus(STATUS.FAIL)
      }
    }
  )

  return (
    <>
      {status === STATUS.SUCCESS && (
        <CAlert color={'success'} className={'d-flex align-items-center'}>
          <CIcon width={24} height={24} icon={cilCheckCircle} className={'flex-shrink-0 me-2'} />
          List created successfully! {' '}<CButton color={"primary"} onClick={() => nav(-1)} size={"sm"} className={"mx-1"}>Return to List</CButton>
        </CAlert>
      )}
      {status === STATUS.FAIL && (
        <CAlert color={'danger'} className={'d-flex align-items-center'}>
          <CIcon width={24} height={24} icon={cilWarning} className={'flex-shrink-0 me-2'} />
          List create failed.
        </CAlert>
      )}
      <CCard>
        <CCardHeader>
          <CCardTitle>Create List</CCardTitle>
        </CCardHeader>
        <CCardBody>
          <ListForm onComplete={async (variables) => createList({variables})} loading={loading} disabled={disabled} />
        </CCardBody>
      </CCard>
    </>
  )
}

export default CreateList

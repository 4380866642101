import React, { useContext, useEffect, useState } from 'react'
import { SideSegment } from '../index'
import ColourChip from '../../Utility/Components/ColourChip'
import { CFormSwitch } from '@coreui/react'
import { AuthContext } from '../../../hooks/auth-context'

const UserList = ({ segment: inSegment, onChange, children, ...props }) => {
  const [segment, setSegment] = useState({})
  const [customOpts, setCustomOpts] = useState([])
  const [showMyLists, setShowMyLists] = useState(false)
  const {
    user: { id: userId },
  } = useContext(AuthContext)
  useEffect(() => {
    setSegment({ ...inSegment, strings: [] })
    if (inSegment.strings) {
      setCustomOpts(
        [...inSegment.strings]
          .filter((el) => {
            if (!showMyLists) {
              return true
            }

            return el.userId === userId
          })
          .sort((a, b) => {
            if (a.name > b.name) {
              return 1
            } else if (a.name < b.name) {
              return -1
            }
            return 0
          })
          .map((el) => ({
            ...el,
            renderName: (
              <div className={'d-flex align-content-center'} key={`${el.name}`}>
                <ColourChip colour={el.colour} />{' '}
                <span style={{ margin: 'auto 0' }}>{el.name}</span>
              </div>
            ),
          })),
      )
    }
  }, [inSegment, showMyLists])

  return (
    <>
      <SideSegment
        segment={segment}
        {...props}
        customOpts={customOpts}
        ops={['or', 'not']}
        onChange={onChange}
        actions={[
          <CFormSwitch
            label={'Show My My Lists'}
            onChange={() => setShowMyLists(!showMyLists)}
            checked={showMyLists}
          />,
        ]}
      ></SideSegment>
    </>
  )
}

export default UserList

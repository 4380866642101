import React from 'react'
import CIcon from '@coreui/icons-react'
import { cidCheckCircle, cilNotes } from '@coreui/icons-pro'

const Features = ({ clinic }) => {
  const { treatment_categories, annotationCount } = clinic
  return (
    <>
      {treatment_categories.includes('healthcare professional led clinic') && (
        <div>
          <CIcon icon={cidCheckCircle} /> HCP Led
        </div>
      )}
      {annotationCount > 0 && (
        <div>
          <CIcon icon={cilNotes} size={'lg'} /> {annotationCount} notes{' '}
        </div>
      )}
    </>
  )
}

export default Features

import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { CButton, CCard, CCardBody, CCardHeader, CCardTitle } from '@coreui/react'
import { CSmartTable } from '@coreui/react-pro'
import { READ_SEARCHES } from '../../../graphql/queries/search-manage'
import { Link } from 'react-router-dom'
import DeleteSearch from './Components/DeleteSearch'
import AssignUsers from './Components/AssignUsers'
import PreviewSearch from './Components/PreviewSearch'

const Searches = () => {
  const [search, setSearch] = useState({})
  const [mappedSegments, setMappedSegments] = useState([])
  const {
    loading,
    refetch,
    data: { ListUserSearch: { entries: searches } } = { ListUserSearch: { entries: [] } },
  } = useQuery(READ_SEARCHES)

  const columns = [
    {
      key: 'name',
    },
    {
      key: 'userCount',
      label: 'Users',
    },
    {
      key: 'options',
      _style: {
        width: '25rem',
      },
    },
  ]

  return (
    <>
      <CCard>
        <CCardHeader>
          <CCardTitle>Manage Searches</CCardTitle>
        </CCardHeader>
        <CCardBody>
          <div className={'d-flex justify-content-between'}>
            <Link to={'create'} className={'btn btn-primary'}>
              Create New Search
            </Link>
            <CButton color={'primary'} onClick={() => refetch()}>
              Reload
            </CButton>
          </div>
          <CSmartTable
            loading={loading}
            columns={columns}
            className={'mt-3'}
            items={searches}
            scopedColumns={{
              name: (item) => (
                <td>
                  <Link to={`edit/${item.id}`}>{item.name}</Link>
                </td>
              ),
              options: (item) => (
                <td align={'right'}>
                  <AssignUsers id={item.id} onComplete={() => refetch()} />{' '}
                  <PreviewSearch id={item.id} item={item} />{' '}
                  <DeleteSearch id={item.id} onComplete={async () => refetch()} />
                </td>
              ),
            }}
          />
        </CCardBody>
      </CCard>
    </>
  )
}

export default Searches

import React, { useState } from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import ListSelect from '../ListSelect'

const AddLocationsToList = ({title, locations = [], exclude = [], onComplete = async () => void 0, ...props }) => {
  const [visible, setVisible] = useState(false)
  let children
  let only

  try {
    only = React.Children.only(props.children)
    if (only) {
      children = React.cloneElement(only, { onClick: () => setVisible(true) })
    }
  } catch (e) {
    if (!only) {
      children = <span onClick={() => setVisible(true)}>{props.children}</span>
    }
  }
  return (
    <>
      {children}
      <CModal
        visible={visible}
        onClose={() => {
          setVisible(false)
        }}
        unmountOnClose
      >
        <CModalHeader>
          <CModalTitle>{title ? title : "Add Location to List"}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <ListSelect
            locations={locations}
            onComplete={(andClose = false) => {
              onComplete()
                .then(() => {
                  if(andClose) {
                    setVisible(false)
                  }
                })
            }}
            exclude={exclude}
            onCancel={() => setVisible(false)}
            listTypes={['user']}
          />
        </CModalBody>
        {/*<CModalFooter>*/}
        {/*  <CButton color={"primary"} onClick={() => setVisible(false)}>Done</CButton>*/}
        {/*</CModalFooter>*/}
      </CModal>
    </>
  )
}

export default AddLocationsToList

import { createContext, useCallback, useEffect, useReducer, useState } from 'react'
import {replaceSegTag, setSegList, setSegTag} from '../tools/set-seg-tag'
import { useSearchParams } from 'react-router-dom'
import {ZOOM_TRANSITION} from "../components/Dashboard/Components/MapSearch/common";

export const SearchContext = createContext({
  view: ""
})
export const SearchDispatchContext = createContext((type = "", value = "" || {}) => void 0)
export const DEFAULT_PAGE_SIZE = 30

const ops = ["or", "and", "not"]

const searchReducer = (state = {}, {type, value}) => {
  let finalState = {...state}
  switch (type) {

    // SEARCH OBJECT MANIPULATIONS
    case "REMOVE_PARAM": {
      const s = {...state}
      let {search} = {...state}
      if(Array.isArray(value)) {
        value.forEach((v) => {
          search = replaceSegTag(search, v, [])
          ops.forEach((op) => {
            search = replaceSegTag(search, `${v}:${op}`, [])
          })
        })
      } else {
        search = replaceSegTag(search, value, [])
        ops.forEach((op) => {
          search = replaceSegTag(search, `${value}:${op}`, [])
        })
      }
      finalState = {
        ...s,
        search: {...search},
        page: 1,

      }
      break;
    }

    case "SET_PARAM": {
      const {name,value:v} = value
      const {search} = {...state}
      finalState = {
        ...state,
        search: setSegTag(search, name, v),
        page: 1,

      }
      break
    }

    case "REPLACE_PARAM": {
      const {name, value:v} = value
      const {search} = {...state}
      finalState = {
        ...state,
        search: replaceSegTag(search, name, v),
        page: 1,

      }
      break
    }

    case "SET_PARAM_LIST": {
      let {search} = {...state}
      finalState = {
        ...state,
        search: setSegList(search, value),
        page: 1,

      }
      break
    }

    case "SET_SEARCH": {
      finalState = {
        ...state,
        search: { ...value },
        page: 1,
      }
      break
    }

    case "CLEAR_SEARCH": {
      let {search, pageSize} = {...state}
      const cSearch = {}
      Object.keys(search)
        .filter((k) => value.includes(k))
        .forEach((k) => {
          cSearch[k] = search[k]
        })

      if(search.zoom && search.zoom < ZOOM_TRANSITION) {
        pageSize = 0
      }

      finalState = {
        ...state,
        search: cSearch,
        page: 1,
        pageSize,
        sort: "",
        sortField: defaultState.sortField,
        sortDirection: defaultState.sortDirection
      }
      break
    }

    // Everything else
    case "SET_PAGE": {
      finalState = {
        ...state,
        page: value
      }
      break
    }
    case  "PAGE_SIZE": {
      finalState = {
        ...state,
        pageSize: value
      }
      break
    }

    case "SORT_FIELD": {
      const s = {...state}
      delete s.sortField
      if(value) {
        finalState = {
          ...s,
          sortField: value
        }
      } else {
        finalState = {
          ...state,
        }
      }
      break;
    }
    case "SORT_DIRECTION": {
      finalState = {
        ...state,
        sortDirection: value
      }
      break;
    }

    case "SORT": {
      const s = {...state}
      delete s.sort
      if(value && value.length > 0) {
        finalState = {
          ...s,
          sort: value,
        }
      } else {
        finalState = s
      }
      break;
    }

    case "SEARCH_VIEW": {
      const s = {...state}
      delete s.view

      if(value && value.length > 0) {
        finalState = {
          ...s,
          view: value
        }
      } else {
        finalState = {
          ...s
        }
      }
      break;
    }

    default: {
      finalState = {...state}
      break;
    }
  }

  // Some final business to attend
  let {view = "", search = {}, pageSize, page, aggregateReturns = []} = finalState

  if(view === "map") {
    aggregateReturns = ['location']
    page = 1
    pageSize = 0
    // const {zoom = 13} = search
    // const has = Object.keys(search)
    //   .filter((k) => ops.map((o) => `location_list:${o}`).includes(k))
    // if(has.length > 0) {
    //   pageSize = 5000
    // } else {
    //   if(zoom < ZOOM_TRANSITION) {
    //     pageSize = 0
    //   } else {
    //     pageSize = 5000
    //   }
    // }
  } else if(view === "data-aggs") {
    aggregateReturns = []
    pageSize = 0
    page = 0
  } else {
    aggregateReturns = []
    pageSize = DEFAULT_PAGE_SIZE
  }

  return {
    ...finalState,
    page,
    pageSize,
    aggregateReturns,
  }
}

const defaultState = {
  page: 0,
  pageSize: DEFAULT_PAGE_SIZE,
  search: {},
  sortDirection: "asc",
  sortField: "",
  initStatus: "from-params",
  view: "",
  aggregateReturns: [],
}

const ACTION_FIELDS = [
  "page",
  "pageSize",
  "sortDirection",
  "sortFeld",
  "view",
]

export const SearchProvider = ({children}) => {

  const [urlSearchParams, setUrlSearchParams] = useSearchParams()
  const [initStatus, setInitStatus] = useState("from-params")

  const extractUrlParams = (params) => {
    let search = {}
    let paramsToExtract = urlSearchParams

    const paramsToReturn = {
      page: 0,
      sort: "",
      search: {}
    }

    if(params) {
      paramsToExtract = params
    }

    for (const [key, value] of paramsToExtract.entries()) {
      if (key === 'page' && value.length > 0) {
        paramsToReturn["page"] = Number(value)
        // page = Number(value)
      } else if (key === "sort" && value.length > 0) {
        paramsToReturn["sort"] = value
      } else if(key !== "search" && Object.keys(defaultState).includes(key)) {
        paramsToReturn[key] = value
      } else {
        search = setSegTag(search, key, value)
      }
    }

    if (!paramsToExtract.has("page")) {
      // paramsToReturn["page"] = 1
    }

    return {
      ...paramsToReturn,
      search,
    }
  }

  const [state, dispatch] = useReducer(searchReducer, defaultState, (arg) => {
    return {
      ...arg,
      ...extractUrlParams(),
      initState: "waiting",
    }
  })

  useEffect(() => {
    if(initStatus === "from-params") {
      setInitStatus("waiting")
    } else {
      const {search = {}, page, sortField, sortDirection = "asc", view} = state
      let searchParams = {...search}
      if (page && page !== 0) {
        searchParams = {...searchParams, page}
      }
      if (sortField && sortField.length > 0) {
        const sort = `${sortField}:${sortDirection ?? "asc"}`
        searchParams = {...searchParams, sort}
      }
      if(view) {
        searchParams = {...searchParams, view}
      }
      setInitStatus("from-search-object")
      setUrlSearchParams(searchParams)
    }
  }, [state]);

  useEffect(() => {
    if(initStatus === "from-search-object") {
      setInitStatus("waiting")
    } else {
      setInitStatus("from-params")
      const {search: s, page, sort, view = ""} = extractUrlParams(urlSearchParams)
      dispatch({type: "SET_SEARCH", value: s})
      dispatch({type: "SET_PAGE", value: page})
      dispatch({type:"SEARCH_VIEW", value:view})
      if(sort.length > 0) {
        const sp = sort.split(":")
        if(sp[0].length > 0) {
          dispatch({type: "SORT_FIELD", value: sp[0]})
          dispatch({type: "SORT_DIRECTION", value: sp[1]})
        }
      }
    }
  }, [urlSearchParams])

  const dispatcher = useCallback((type, value) => {
    dispatch({type, value})
  }, [dispatch]);


  return (
    <SearchContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatcher}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchContext.Provider>
  )
}
